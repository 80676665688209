<template>
  <ul>
    <li
      v-for="frase in frases"
      :key="frase"
    >
      <button-component
        class="w-full text-left text-xs text-zinc-500 hover:bg-zinc-100 rounded-lg p-2"
        @click="$emit('close', frase.replaceAll('%%', ''))"
      >
        <chat-prompter-frase
          :frase="frase"
        />
      </button-component>
    </li>
  </ul>
</template> 
<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  PropType,
  toRefs,
} from 'vue'
import ButtonComponent from './button-component.vue';
import ChatPrompterFrase from './chat-prompter-frase.vue';
const props = defineProps({
  frases: {
    type: Array as PropType<string[]>,
    required: true,
  },
})
const { 
  frases,
} = toRefs(props)
defineEmits([
  'close',
])
</script>