<template>
  <div
    class="w-10 h-10 object-cover rounded-full mt-2"
    :style="botStyle"
  >
    <img
      alt="avatar"
      :src="navigatorAvatar || require('@/assets/bot-avatar.png')"
    >
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import eventBus from '@/utils/event-bus';
import store from '@/store';
const color1 = ref(store.color1)
const botStyle = ref({
  'background-color': color1,
})
const navigatorAvatar = ref(store.navigatorAvatar)
const navigatorName = ref(store.navigatorName)
const handleColor1 = (color: string) => {
  color1.value = color
}
const handleNavigatorAvatar = (avatar: string) => {
  navigatorAvatar.value = avatar
}
const handleNavigatorName = (name: string) => {
  navigatorName.value = name
}
onMounted (() => {
  eventBus.on('change-color1', handleColor1)
  eventBus.on('change-navigator-name', handleNavigatorName)
  eventBus.on('change-navigator-avatar', handleNavigatorAvatar)
})
onUnmounted(() => {
  eventBus.off('change-color1', handleColor1)
  eventBus.off('change-navigator-name', handleNavigatorName)
  eventBus.off('change-navigator-avatar', handleNavigatorAvatar)
})
</script>

<style lang="scss" scoped>
img {
  display: block;
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
  width: 40px;
}
</style>
