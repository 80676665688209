<template>
  <div id="box">
    <chat-box-main
      aside-width="screen"
      :is-open="false"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  inject,
  onMounted,
} from 'vue'
import ChatBoxMain from '@/components/chat-box-main.vue'

type IBoxStyles = [string, string][]

const device = inject('device')
const isMobile = device === 'mobile'

onMounted (() => {
  handleBoxWhenMobile()
})

const handleBoxWhenMobile = () => {
  if(isMobile){
    const box = document.getElementById('box') as HTMLElement
    const boxStyles: IBoxStyles = [
      ['width', '100vw'],
      ['height', '100vh'],
      ['right', '0'],
      ['bottom', '0'],
      ['border-radius', 'unset'],
    ]
    boxStyles.forEach(([prop, value]) => {
      box.style.setProperty(prop, value)
    })
  }
}

</script>

<style lang="scss" scoped>
#box {
  position: absolute;
  right: 1.5rem;
  background: #eaeae8;
  z-index: 2147483647;
  width: calc(100% - 34px);
  height: calc(100% - 24px);
  transition: right 0.5s;
  bottom: 1.5rem;
  border-radius: .5rem;
  overflow: hidden;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
</style>
