import api from "@/services/api";
export type TIframePostion = {
  inset: string;
}

export async function updateIframePosition({inset}: TIframePostion) {
  const axiosApi = await api(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_LOCAL_TOKEN).axios()
  axiosApi.put('/api/v1/navigator/position', {
    inset,
  }).catch((error) => {
    console.error('updateIframePosition error', error)
  })
}
