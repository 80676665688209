<template>
  <span
    v-for="(frasePart, index) in fraseParts"
    :key="frasePart"
    :style="{fontWeight: index % 2 !== 0 ? 'bold' : 'normal'}"
  >
    {{ frasePart }}
  </span>
</template> 
<script setup lang="ts">
import {
  defineProps,
  toRefs,
} from 'vue'
const props = defineProps({
  frase: {
    type: String,
    required: true,
  },
})
const { 
  frase,
} = toRefs(props)
const fraseParts = frase.value.split('%%')
</script>