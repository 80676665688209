<template>
  <div class="article-dialog-card flex justify-center gap-4 w-1/2">
    <button
      class="
        relative flex flex-col gap-2 rounded-2xl border border-token-border-light px-3 pb-4 pt-3 text-start
        align-top text-[15px] shadow-xxs transition enabled:hover:bg-token-main-surface-secondary 
        disabled:cursor-not-allowed
      "
    >
      <ph-icon
        :color="iconColor"
        :icon="icon"
        :size="24"
      />
      <div class="line-clamp-3 max-w-full text-balance break-word text-gray-600">
        <span class="font-normal">
          {{ text }}
        </span>
        <span class="font-bold">
          {{ boldText }}
        </span>
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  toRefs,
} from 'vue'
import PhIcon from './ph-icon.vue';

const props = defineProps({
  boldText: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  iconColor: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
})

const {
  icon,
  text,
} = toRefs(props)
</script>

<style scoped lang="scss">
.article-dialog-card button {
  transition: background-color .2s;
}
.article-dialog-card button:hover {
  background-color: rgba(0, 0, 0, .03);
}
</style>