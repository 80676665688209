<template>
  <div class="px-3 mt-1 mb-2">
    <div
      class="flex items-center"
      @click="$emit('handle-open')"
    >
      <button-component
        :tooltip-text="refIconTooltipText"
        class="flex items-center"
      >
        <span class="text-neutral-500 cursor-pointer text-default">
          Encontrei alguma relevância da sua pergunta nesses artigos:
        </span>
        <ph-icon
          :icon="iconName"
          cursor="pointer"
          color="neutral-500"
          :size="18"
          class="ml-2"
        />
      </button-component>
    </div>
    <div
      v-if="isOpen"
      class="border border-neutral-200 rounded-lg p-2 mt-2"
    >
      <div
        v-for="article in articles"
        :key="article.title"
        class="flex items-center link text-default"
      >
        <div class="h-7 w-7 min-w-7 flex relative">
          <ph-icon
            icon="PhLightbulb"
            :color="systemColor1"
            :size="20"
            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <a
          :href="handleUrl(article)"
          target="_blank"
          class="ml-2 font-bold"
        >{{ article.title }}</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue'
import PhIcon from './ph-icon.vue';
import store from '@/store';
import ButtonComponent from './button-component.vue';

type TArticle = {
  title: string;
  article: number;
}

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  articles: {
    type: Array as unknown as PropType<TArticle[]>,
    default: [] as TArticle[],
    required: false,
  },
})
const {
  articles,
  isOpen,
} = toRefs(props)

const iconName = ref('PhCaretDown')
const refIconTooltipText = ref('Exibir Resferências')
const systemColor1 = store.userInfo.custom.color1
function handleUrl({title, article}: TArticle) {
  const titleWithOutAccent = removeAccent(title);
  const finalTitle = titleWithOutAccent
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
  return `${store.origin}/wiki/${article}/${finalTitle}`
}
function removeAccent(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
defineEmits(['handle-open'])
watch(isOpen, (isOpen) => {
  iconName.value = isOpen ? 'PhCaretUp' : 'PhCaretDown'
  refIconTooltipText.value = isOpen ? 'Ocultar Referências' : 'Exibir Referências'
})
</script>

<style lang="scss" scoped>
.link + .link {
  margin-top: 0.5rem;
}
</style>