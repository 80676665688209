<template>
  <article-dialog
    v-if="type !== 'chat' && isArticlePage && articleFilter === null"
    :article-name="articleName"
    :user-name="userName"
    :wiki-name="wikiName"
    @article-selected="handleArticleSelected"
    @wiki-selected="handleWikiSelected"
  />
  <default-user-welcome
    v-else
    :article-name="articleName"
    :article-filter="articleFilter || 0"
    :user-name="userName"
  />
</template>

<script lang="ts" setup>
import {
  inject,
  ref,
  onMounted,
  onUnmounted,
} from 'vue'
import store, {setArticleFilter} from '@/store';
import eventBus from '@/utils/event-bus';
import { TUserInfo, TWikiInfo } from '@/types';
import ArticleDialog from './article-dialog.vue';
import DefaultUserWelcome from './default-user-welcome.vue';
import useIframeControl from '@/hooks/iframe';

const {
  userInfo,
  wikiInfo,
} = store
const articleFilter = ref(null as number | null)
const userName = ref(userInfo.name || '')
const isArticlePage = ref(false)
const articleId = ref(0)
const wikiName = ref('')
const articleName = ref(wikiInfo?.article?.name || '')
const iframeControl = useIframeControl()
const type = inject('viewType') as string
function updateUserName (newUserInfo: TUserInfo) {
  userName.value = newUserInfo.name
}
function handleArticleId(pathname: string) {
  const pathParts = pathname.split('/')
  isArticlePage.value = pathParts.length >= 3 && pathParts[1] === 'wiki' && /^\d+$/.test(pathParts[2])
  articleId.value = parseInt(pathParts[2])
}
function handleWikiInfo(newWikiInfo: TWikiInfo) {
  const {
    name: newWikiName,
    article: {
      name: newArticleName,
      pathname,
    },
  } = newWikiInfo
  wikiName.value = newWikiName
  articleName.value = newArticleName
  handleArticleId(pathname)
}
function handleArticleSelected() {
  setArticleFilter(articleId.value)
  articleFilter.value = articleId.value
}
function handleWikiSelected() {
  setArticleFilter(0)
  articleFilter.value = 0
}
function handleNewChat() {
  articleFilter.value = null
}
onMounted(() => {
  iframeControl.requestArticleName()
  eventBus.on('user-info-updated', updateUserName)
  eventBus.on('wiki-info-updated', handleWikiInfo)
  eventBus.on('new-chat', handleNewChat)
})
onUnmounted(() => {
  eventBus.off('user-info-updated', updateUserName)
  eventBus.off('wiki-info-updated', handleWikiInfo)
  eventBus.off('new-chat', handleNewChat)
})
</script>