import { reactive, readonly } from 'vue'
import eventBus from '@/utils/event-bus'
import {
  IHistoryChat,
  TPowerappProfile,
  TUserInfo,
  TWikiInfo,
} from '@/types';
import useIframeControl from '@/hooks/iframe';

export type StoreState = {
  activeChatId: string;
  apiURL: string;
  articleFilter: number | null;
  asideMenuIsOpen: boolean;
  color1: string;
  isOpen: boolean;
  chatHistory: IHistoryChat[];
  navigatorAvatar: string;
  navigatorName: string;
  origin: string;
  powerappProfile: TPowerappProfile;
  userInfo: TUserInfo | Record<string, never>;
  wikiInfo: TWikiInfo;
}

const initialState: StoreState = {
  activeChatId: '',
  apiURL: process.env.VUE_APP_BEEBOT_NAVIGATOR_API_URL || '',
  articleFilter: null,
  asideMenuIsOpen: false,
  color1: '#fbcd32',
  isOpen: false,
  chatHistory: [],
  navigatorAvatar: '../assets/bot-avatar.png',
  navigatorName: 'Beebot Navigator',
  origin: '',
  powerappProfile: {} as TPowerappProfile,
  userInfo: {},
  wikiInfo: {} as TWikiInfo,
}

const state = reactive<StoreState>({...initialState})

export function setColor1 (color: string): void {
  state.color1 = color
  eventBus.emit('change-color1', color)
}

export function setActiveChatId (chatId: string): void {
  state.activeChatId = chatId
}

export function setOrigin (origin: string): void {
  state.origin = origin
}

export function toggleAsideMenu (): void {
  const newState = !state.asideMenuIsOpen
  state.asideMenuIsOpen = newState
  eventBus.emit('toggle-aside-menu', {newState})
}
export function setUserInfo (userInfo: TUserInfo | Record<string, never>): void {
  const idioms = ['', 'pt-BR','es', 'pt-PT', 'en-US', 'fr']
  userInfo.idiom = idioms[userInfo.idiomId]
  state.userInfo = userInfo
  eventBus.emit('user-info-updated', userInfo)
}
export function updateApiToken (apiToken: string): void {
  state.userInfo.apiToken = process.env.VUE_APP_API_TOKEN || apiToken
}
export function setMessageHistory (chatHistory: IHistoryChat[]): void {
  state.chatHistory = chatHistory
  eventBus.emit('message-history-updated', chatHistory)
  const iframe = useIframeControl()
  iframe.sendChatHistory(JSON.stringify(chatHistory))
}

export function deleteChatHistory (chatId: string): void {
  const chatHistory = state.chatHistory.filter((chat) => chat.chat_id !== chatId)
  state.chatHistory = chatHistory
  eventBus.emit('message-history-updated', chatHistory)
  const iframe = useIframeControl()
  iframe.deleteChat(chatId)
}

export function resetStore () {
  Object.assign(state, initialState)
}

export function setNavigatorAvatar (avatar: string) {
  state.navigatorAvatar = avatar
  eventBus.emit('change-navigator-avatar', avatar)
}

export function setnavigatorName (name: string) {
  state.navigatorName = name
  eventBus.emit('change-navigator-name', name)
}

export function setPowerappProfile (profile: TPowerappProfile) {
  state.powerappProfile = profile
  setColor1(profile.color1)
  setnavigatorName(profile.botName)
  setNavigatorAvatar(profile.avatarURL)
}

export function setWikiInfo(wikiInfo: TWikiInfo){
  state.wikiInfo = wikiInfo
  eventBus.emit('wiki-info-updated', wikiInfo)
}

export function setArticleFilter(articleFilter: number | null){
  state.articleFilter = articleFilter
  eventBus.emit('article-filter-updated', articleFilter)
}

export default readonly(state)