<template>
  <header-menu
    tooltip-text="Exibir Menu"
    :title="title"
  >
    <div
      class="flex items-center cursor-pointer"
      @click="handleNewChat"
    >
      <button-component
        tooltip-text="Nova conversa"
        class="flex items-center"
      >
        <ph-icon
          icon="PhPlus"
        />
        <span
          v-if="viewType === 'chat'"
          class="select-none"
        >
          Nova Conversa
        </span>
      </button-component>
    </div>
    <button-component
      v-if="viewType === 'widget' && device === 'desktop'"
      tooltip-text="Abrir em tela cheia"
    >
      <ph-icon
        icon="PhArrowSquareOut"
        class="mx-2"
        @click="iframe.openInNewTab()"
      />
    </button-component>
    <button-component
      v-if="viewType !== 'chat'"
      tooltip-text="Fechar"
      @click="iframe.notifyClose()"
    >
      <ph-icon
        icon="PhX"
      />
    </button-component>
  </header-menu>
</template>

<script setup lang="ts">
import {
  defineProps,
  inject,
  toRefs,
} from 'vue'
import HeaderMenu from './header-menu.vue'
import PhIcon from './ph-icon.vue'
import eventBus from '@/utils/event-bus'
import useIframeControl from '@/hooks/iframe'
import ButtonComponent from './button-component.vue'

const iframe = useIframeControl()
const viewType = inject('viewType') as string
const device = inject('device') as string
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
})
const { title } = toRefs(props)
const handleNewChat = () => {
  eventBus.emit('new-chat')
  iframe.clearActiveChatId()
}
</script>