<template>
  <div
    v-if="isModalOpen"
    id="message-modal"
    class="fixed top-0 left-0 rounded bg-white border border-token-border-light p-4 drop-shadow-md"
  >
    <header class="flex justify-center pb-2">
      <h1 class="leading-normal">
        {{ modalMessage }}
      </h1>
    </header>
    <footer class="flex justify-end pt-3 border-t">
      <button-component
        v-for="(btn, index) in btnConfig"
        id="close-message-modal"
        :key="`btn-${index}`"
        class="hover:bg-inherit rounded-full relative btn-primary px-4 py-1 ml-2"
        :class="btn.type"
        @click="handleBtnClick(btn)"
      >
        <span
          class="flex items-center justify-center color-inherit"
        >
          {{ btn.text }}
        </span>
      </button-component>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import {
  nextTick,
  ref,
  Ref,
  onMounted,
  onUnmounted,
} from 'vue';
import eventBus from '@/utils/event-bus';
import ButtonComponent from './button-component.vue';
type TModalStyle = string[][]

type TBtnConfig = {
  eventName?: string;
  eventValue?: unknown;
  text: string;
  type: 'danger' | 'success' | 'default';
}
export type TSetModal = {
  btn: TBtnConfig[];
  isOpen?: boolean;
  message: string;
  subtractItself?: 'top' | 'bottom';
  style?: TModalStyle;
}


const isModalOpen = ref(false)
const modalMessage = ref('')
const btnConfig = ref([]) as Ref<TBtnConfig[]>
const handleBtnClick = ({eventName, eventValue}: TBtnConfig) => {
  if(eventName){
    eventBus.emit(eventName, eventValue)
  }
  eventBus.emit('open-close-modal', false)
}

const setModal = ({
  btn,
  isOpen,
  message,
  subtractItself,
  style,
}: TSetModal) => {
  btnConfig.value = btn
  isModalOpen.value = isOpen || true
  modalMessage.value = message
  if(style)
    return nextTick(() => setModalStyles(style, subtractItself))
  nextTick(handleModalDefaultPosition)
}

const handleModalDefaultPosition = () => {
  setModalStyles([
    ['left', '50%'],
    ['max-width', '350px'],
    ['top', '50%'],
    ['transform', 'translate(-50%, -50%)'],
    ['width', 'unset'],
  ])
}

const setModalStyles = (styles: string[][], subtractItself?: 'top' | 'bottom') => {
  const modal = document.getElementById('message-modal')
  if(!modal || !styles) return
  styles.forEach(([key, value]) => {
    if(subtractItself && subtractItself === key){
      const modalHeight = modal.getBoundingClientRect().height
      const newValue = parseInt(value.replace(/\D/g, ''))
      return modal.style.setProperty(key, `${newValue - modalHeight}px`)
    }
    modal.style.setProperty(key, value)
  })
}

const openCloseMessageModal = (state: boolean) => {
  isModalOpen.value = state
}

onMounted(() => {
  eventBus.on('open-close-modal', openCloseMessageModal)
  eventBus.on('set-modal', setModal)
})
onUnmounted(() => {
  eventBus.off('open-close-modal', openCloseMessageModal)
  eventBus.off('set-modal', setModal)
})

</script>

<style lang="scss">
#message-modal {
  z-index: 9999999999;
  footer {
    border-top: 1px solid #dedede;
    .danger {
      background-color: #ee2b25;
      span {
        color: white;
      }
    }
    .danger:hover {
      background-color: #d12822;
    }
    .success {
      background-color: #00C853;
      span {
        color: white;
      }
    }
    .success:hover {
      background-color: #00b24a;
    }
  }
}
</style>
