<template>
  <header class="flex items-center justify-between p-2 ps-3 pe-3 h-12 bg-white w-full relative">
    <div class="flex items-center">
      <button-component
        :tooltip-text="tooltipText"
        classes="ml-2"
      >
        <ph-icon
          icon="PhList"
          @click="toggleAsideMenu"
        />
      </button-component>
      <span
        class="navigator-name text-lg font-bold user-select ml-5"
        :style="navigatorNameStyle"
      >
        {{ title }}
      </span>
    </div>

    <div class="flex items-center">
      <slot />
    </div>
  </header>
</template>

<script setup lang="ts">
import {
  CSSProperties,
  defineProps,
  ref,
  toRefs,
} from 'vue'
import PhIcon from '@/components/ph-icon.vue'
import ButtonComponent from './button-component.vue';
import { toggleAsideMenu } from '@/store'
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  tooltipText: {
    type: String,
    required: true,
  },
})
const { title } = toRefs(props)
const navigatorNameStyle = ref<CSSProperties>({
  display: 'initial',
})
</script>

<style lang="scss">
header {
  div {
    * {
      margin: .25rem;
    }
  }
  span {
    color: #707070;
  }
}
</style>