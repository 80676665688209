import {
  createChat,
  listChat,
} from '@/services/BotService'
import api from '@/services/api'
import store, { deleteChatHistory, setMessageHistory } from '@/store'
import useIframeControl from '@/hooks/iframe'
import eventBus from './event-bus'

export type TAdditionalKwargs = {
  articles: {
    title: string;
    article: number;
  }[];
  created_at: string;
  id: string;
  parent_id: string;
}

export type TGetChatMessages = {
  role: "user" | "assistant";
  content: string;
  additional_kwargs: TAdditionalKwargs;
}

export async function handleChatId (messageId: string, title: string): Promise<string> {
  if(messageId)
    return messageId
  return await createChat(title)
}

export async function handleChatHistory() {
  const chatList = (await listChat()).map((chat) => ({
    ...chat,
    title: chat.title || 'novo chat',
  }))
  setMessageHistory(chatList)
  const iframeControl = useIframeControl()
  iframeControl.sendChatHistory(JSON.stringify(chatList))
}

export async function getChatMessages (messageId: string): Promise<TGetChatMessages[]> {
  if(!messageId) return []
  try {
    const axiosApi = await api().axios()
    const messages = await axiosApi.get(`/api/chat/${messageId}`)
    return messages.data
  } catch (error) {
    eventBus.emit('set-modal', {
      btn: [{
        text: 'Ok',
        type: 'default',
      }],
      isOpen: true,
      message: 'Não foi possível caregar o chat. Por favor tente novamente.',
    })
    return []
  }
}

export async function handleDeleteChat (chatId: string) {
  const axiosApi = await api().axios()
  await axiosApi.delete(`/api/chat/${chatId}`)
    .then(() => {
      const iframeControl = useIframeControl()
      iframeControl.deleteChat(chatId)
      deleteChatHistory(chatId)
      const activeChatid = store.activeChatId
      if(activeChatid === chatId){
        const iframeControl = useIframeControl()
        iframeControl.clearActiveChatId()
        eventBus.emit('new-chat')
      }
      eventBus.emit('set-system-feedback', 'Conversa excluída com sucesso')
    }).catch((error) => {
      console.error(error)
      eventBus.emit('set-modal', {
        btn: [{
          text: 'Ok',
          type: 'default',
        }],
        isOpen: true,
        message: 'Não foi possível remover o chat',
      })
    })
}

export function classifyDate (date: string) {
  const idiom = store.userInfo?.idiom || 'pt-BR'
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const lasWeek = new Date(today)
  lasWeek.setDate(lasWeek.getDate() - 7)
  const lastMonth = new Date(today)
  lastMonth.setMonth(lastMonth.getMonth() - 1)
  const dateToCompare = new Date(date)
  if(dateToCompare.toDateString() === today.toDateString())
    return 'hoje'
  if(dateToCompare.toDateString() === yesterday.toDateString())
    return 'ontem'
  if(dateToCompare > lasWeek)
    return 'últimos 7 dias'
  if(dateToCompare > lastMonth)
    return 'últimos 30 dias'
  if(dateToCompare.getFullYear() === today.getFullYear())
    return dateToCompare.toLocaleDateString(idiom, {month: 'long'})
  return dateToCompare.toLocaleDateString(idiom, {year: 'numeric'})
}

