import useIframeControl from '@/hooks/iframe';
import { mountApp } from '@/main';
import { messageEvents } from '@/utils/message-events';

type TStore = {
  timeOutId: NodeJS.Timeout | null;
}

const store: TStore = {
  timeOutId: null,
}

export function handleUserPermission() {
  window.addEventListener('message', ({ data }) => messageEvents(data))
  const iframeControl = useIframeControl()
  iframeControl.requestAppAuth()
  store.timeOutId = setTimeout(() => {
    createNotPermissionMessage()
  },5000)
}

export function handleSecret(message: string) {
  if(message !== 'init ok')
    return createNotPermissionMessage()

  clearTimeout(store.timeOutId as NodeJS.Timeout)
  preMountApp()
  mountApp()
}

export function removeLoader() {
  const loader = document.querySelector('.loader-container') as HTMLElement
  loader.remove()
}

function preMountApp() {
  const body = document.querySelector('body') as HTMLElement
  const app = document.createElement('div')
  const noPermissionMessage = document.querySelector('#no-permission-message') as HTMLElement
  app.id = 'app'
  noPermissionMessage?.remove()
  body.appendChild(app)
}

function createNotPermissionMessage() {
  const body = document.querySelector('body') as HTMLElement
  const section = document.createElement('section')
  const h1 = document.createElement('h1')
  
  const sectionStyles = [
    ['background-color', '#fff'],
    ['padding', '16px'],
    ['display', 'flex'],
    ['height', '100vh'],
    ['width', '100vw'],
    ['align-items', 'center'],
    ['justify-content', 'center'],
  ]
  const styledSection = setStyles(section, sectionStyles)
  styledSection.id = 'no-permission-message'
  
  const h1Styles = [
    ['font-size', '48px'],
    ['text-align', 'center'],
  ]

  h1.innerText = 'Voce não tem permissão para acessar esta aplicação'
  const styledH1 = setStyles(h1, h1Styles)
  
  section.appendChild(styledH1)
  body.innerHTML = ''
  body.appendChild(styledSection)
}

function setStyles (element: HTMLElement, styles: string[][] = []) {
  styles.forEach(([key, value]) => element.style.setProperty(key, value))
  return element
}
