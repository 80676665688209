<template>
  <chat-box-main />
</template>

<script lang="ts" setup>
import {
  onMounted,
} from 'vue';
import ChatBoxMain from '@/components/chat-box-main.vue';
import { toggleAsideMenu } from '@/store';

onMounted(() => {
  toggleAsideMenu();
});
</script>

