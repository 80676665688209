<template>
  <div class="history-menu mt-3">
    <span class="category pl-2 font-bold text-default">{{ categoryName }}</span>
    <ul class="pl-2">
      <li
        v-for="item in history"
        :id="`history-menu-${item.chat_id}`"
        :key="item.chat_id"
        class="flex justify-between items-center px-2 py-1 hover:bg-gray-100 rounded relative text-gray-700 cursor-pointer"
        :style="handleLiStyle(item.chat_id)"
        @click.stop="handleClick(item.chat_id)"
      >
        <span class="text-default">{{ item.title }}</span>
        <button-component
          not-rounded
          class="opacity-0 p-1.5 rounded-full bg-red-200 relative"
          tooltip-text="Excluir conversa"
          @click.stop="handleDeleteChatConfirm(item.chat_id)"
        >
          <div>
            <ph-icon
              icon="PhTrash"
              color="#d84315"
              :size="18"
            />
          </div>
        </button-component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  inject,
  toRefs,
  PropType,
} from 'vue'
import { toggleAsideMenu } from '@/store';
import { IHistoryChat } from '@/types'
import ButtonComponent from './button-component.vue';
import PhIcon from './ph-icon.vue';
import eventBus from '@/utils/event-bus';
import { getChatMessages } from '@/utils/message-history';
import { TSetModal } from './message-modal.vue';

const props = defineProps({
  activeChat: {
    type: String as PropType<string>,
      default: '',
    required: false,
  },
  categoryName: {
    type: String as PropType<string>,
    required: true,
  },
  history: {
    type: Array as PropType<IHistoryChat[]>,
    required: true,
  },
})
const {
  activeChat,
  categoryName,
  history,
} = toRefs(props)
const type = inject('viewType') as string
const handleHistory = async (chatId: string) => {
  const chatHistory = await getChatMessages(chatId)
  eventBus.emit('load-unique-message', {chatId, chatHistory})
}
const handleClick = (chatId: string) => {
  handleHistory(chatId)
  if(type !== 'chat')
    toggleAsideMenu()
}

function handleDeleteChatConfirm(chatId: string) {
  const target = document.querySelector(`#history-menu-${chatId} button`)
  if(!target) return
  const {
    height,
    left,
    top,
  } = target.getBoundingClientRect()
  const {innerHeight} = window
  const margin = 4
  const aboveHalfScreen = top < (innerHeight * .75)
  const subtractItself = aboveHalfScreen ? undefined : 'top'
  const positionTop = aboveHalfScreen ? (top + margin + height) : (top - margin)
  const modalInfo: TSetModal = {
    btn: [{
      text: 'Cancelar',
      type: 'default',
    },{
      text: 'Excluir',
      type: 'danger',
      eventName: 'delete-chat',
      eventValue: chatId,
    }],
    isOpen: true,
    message: 'Deseja excluir a conversa? Essa ação não poderá ser desfeita.',
    subtractItself,
    style: [
      ['max-width', '350px'],
      ['top', `${positionTop}px`],
      ['transform', 'translateX(-50%)'],
      ...getStyles(left),
    ],
  }
  eventBus.emit('set-modal', modalInfo)
}
function getStyles(left: number) {
  const chatStyle = [
    ['left', `${left}px`],
  ]
  const notChatStyle = [
    ['left', '50%'],
    ['width', '80%'],
  ]
  return type === 'chat' ? chatStyle : notChatStyle
}
function handleLiStyle(chatId: string) {
  return {
    'background-color': activeChat.value === chatId ? 'rgba(0,0,0,.05)' : '',
  }
}
</script>

<style lang="scss" scoped>
.history-menu {
  .category {
    display: block;
    color: #9B9B9B;
    margin-bottom: .5rem;
  }
  span:first-letter {
    text-transform: uppercase;
  }
  ul {
    li {
      .icon {
        margin-right: .5rem;
      }
      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    li:hover button {
      opacity: 1;
    }
  }
}
</style>