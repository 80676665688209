<template>
  <div
    class="chat-prompter absolute w-full bottom-4 rounded-lg border border-solid bg-white p-4"
    :style="chatPrompterStyles"
  >
    <header class="flex justify-between">
      <h1 class="text-base font-bold text-zinc-500">
        Escolha uma maneira para que eu possa te ajudar!
      </h1>
      <button-component
        classes="hover:bg-gray-100 rounded-full p-2"
        @click="$emit('close')"
      >
        <ph-icon
          icon="PhX"
          :size="18"
        />
      </button-component>
    </header>
    <section>
      <chat-prompter-frases
        :frases="frases"
        @close="$emit('close', $event)"
      />
      <span class="text-xs italic text-zinc-500">
        Seja o mais específico possível em seu pedido! Assim, posso te ajudar melhor.
      </span>
    </section>
  </div>
</template> 
<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  toRefs,
} from 'vue'
import ButtonComponent from './button-component.vue';
import PhIcon from './ph-icon.vue';
import ChatPrompterFrases from './chat-prompter-frases.vue';
const props = defineProps({
  color: {
    type: String,
    default: '',
    required: false,
  },
})
const { color } = toRefs(props)
defineEmits([
  'close',
])
const margin = 16
const textareaHeight = 57
const chatPrompterStyles = {
  borderColor: color.value,
  bottom: `${margin + textareaHeight}px`,
}
const frases = [
  'Tenho uma %%dúvida sobre o assunto%% (...). Minha dúvida é: (...).',
  'Faça um %%passo a passo%% de como...',
  'Crie uma %%lista numerada dos principais pontos%% sobre o assunto...',
  'Faça um %%resumo%% dessa resposta.',
  'Quero uma %%resposta mais completa%%, por favor.',
  'Crie uma %%tabela%% com base na resposta anterior',
  'Preciso %%enviar essa informação para uma pessoa por telefone%%. Como eu poderia falar?',
  'Como eu poderia %%enviar essa resposta por e-mail%% para uma pessoa? Essa mensagem precisa ser cordial, didática e objetiva.',
  '%%Mudando de assunto%%, agora quero falar sobre...',
]
</script>