<template>
  <div 
    v-if="isShowing"
    class="success absolute left-1/2 p-2 rounded-lg flex items-center"
    :class="handleClasses()"
    @click="$emit('open-close')"
  >
    <ph-icon
      color="#303030"
      :size="16"
      icon="PhCheckCircle"
    />
    <span class="mx-2 text-default">
      {{ text }}
    </span>
    <ph-icon
      color="#303030"
      :size="16"
      icon="PhX"
    />
  </div>
</template>
<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  ref,
  toRefs,
  watch,
} from 'vue'
import PhIcon from './ph-icon.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
})
const {
  isOpen,
  text,
} = toRefs(props)
defineEmits([
  'open-close',
])
const isShowing = ref(false)
function handleClasses() {
  return {
    'opacity-100': isOpen.value,
    'opacity-0': !isOpen.value,
  }
}
function handleIsShowing(isOpen: boolean) {
  const time = isOpen ? 1 : 400
  setTimeout(() => isShowing.value = isOpen, time)
}
watch(isOpen, newValue => {
  handleIsShowing(newValue)
})
</script>
<style scoped lang="scss">
div {
  z-index: 999999999;
}
.success{
  background-color: #D1E5CF;
  color: #303030;
  top: -4rem;
  transform: translateX(-50%);
  transition: all ease-in-out .4s;
}
</style>