<template>
  <component
    :is="icon"
    :size="size"
    :style="handleStyles()"
    :color="color"
  />
</template>

<script setup lang="ts">
import {
  defineProps,
  toRefs,
} from 'vue'
const props = defineProps({
  color: {
    type: String,
    default: '#333',
  },
  icon: {
    type: String,
    required: true,
  },
  cursor: {
    type: String,
    default: 'inherit',
  },
  size: {
    type: Number,
    default: 22,
  },
})
const {
  color,
  icon,
  cursor,
  size,
} = toRefs(props)
const handleStyles = () => {
  return {
    'cursor': cursor.value,
  }
}
</script>