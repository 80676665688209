<template>
  <div class="m-auto w-full max-w-6xl px-8 lg:px-24 pb-16">
    <div
      class="flex"
    >
      <div class="mr-3">
        <bot-avatar />
      </div> 
      <div class="mt-2 mb-4 text-3xl text-gray-800 font-semibold text-left">
        <div>
          <div class="capitalize line-clamp-1 select-none">
            olá, {{ userName }}
          </div> 
          <div style="">
            <div class="font-medium text-gray-400 select-none">
              Sobre o que você gostaria de falar?
            </div>
          </div>
        </div>
      </div> 
    </div>
    <div class="mt-12 flex justify-evenly gap-4">
      <article-dialog-card
        icon="PhLightbulb"
        :icon-color="iconColor1"
        :bold-text="wikiName"
        text="Falar sobre qualquer assunto da "
        @click="$emit('wiki-selected')"
      />
      <article-dialog-card
        icon="PhScroll"
        :icon-color="iconColor2"
        :bold-text="articleName"
        text="Falar sobre o artigo "
        @click="$emit('article-selected')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps,
  ref,
  onMounted,
  onUnmounted,
  toRefs,
} from 'vue'
import BotAvatar from './bot-avatar.vue';
import ArticleDialogCard from './article-dialog-card.vue';
import store from '@/store';
import eventBus from '@/utils/event-bus';
import { TUserInfo } from '@/types';

const props = defineProps({
  articleName: {
    type: String,
    required: true,
  },
  userName: {
    type: String,
    required: true,
  },
  wikiName: {
    type: String,
    required: true,
  },
})
const {
  articleName,
  userName,
  wikiName,
} = toRefs(props)
const iconColor1 = ref(store.userInfo.custom?.color1 || '')
const iconColor2 = ref(store.userInfo.custom?.color2 || '')
defineEmits([
  'wiki-selected',
  'article-selected',
])
function handleNewColor(newUserInfo: TUserInfo) {
  iconColor1.value = newUserInfo.custom?.color1 || ''
  iconColor2.value = newUserInfo.custom?.color2 || ''
}
onMounted(() => {
  eventBus.on('user-info-updated', handleNewColor)
})
onUnmounted(() => {
  eventBus.off('user-info-updated', handleNewColor)
})
</script>