<template>
  <div class="history-item flex w-full">
    <div :class="handleAvatarClasses()">
      <bot-avatar v-if="!isUser" />
      <div
        v-else
        class="w-10 h-10 object-cover rounded-full"
      />
    </div>
    <div
      class="w-full overflow-hidden pl-1 flex"
      :class="handleAlignUser()"
    >
      <div
        class="
          prose chat-assistant w-fit max-w-full prose-headings:my-0 min-w-44
          prose-headings:-mb-4 prose-p:m-0 prose-p:-mb-6 prose-pre:my-0 prose-table:my-0 
          prose-blockquote:my-0 prose-img:my-0 prose-ul:-my-4 prose-ol:-my-4 prose-li:-my-3 
          prose-ul:-mb-6 prose-ol:-mb-8 prose-ol:p-0 prose-li:-mb-4 whitespace-pre-line
        "
      >
        <div>
          <div class="w-full">
            <loader-animation v-if="isLoading" />
            <div
              v-else
              class="message"
              :class="handleBGUser()"
            >
              <div>
                <div class="rounded-3xl px-3 pb-2 pt-1 text-default">
                  <span
                    v-if="role === 'user'"
                    class="px-1.5 mt-1.5 inline-block"
                    v-text="result"
                  />
                  <span
                    v-else
                    v-dompurify-html="result"
                  />
                </div>
                <reference-link
                  v-if="!isStreaming && additionalKwargs && additionalKwargs.articles && additionalKwargs.articles.length"
                  :is-open="referenceLinksIsOpen"
                  :articles="additionalKwargs.articles"
                  @handle-open="handleOpenCLoseReferenceLinks"
                />
                <chat-history-item-footer
                  v-if="!isStreaming"
                  :is-user="isUser"
                  :like-weight="likeWeight ? 'fill' : 'regular'"
                  :dislike-weight="dislikeWeight ? 'fill' : 'regular'"
                  @copy-text="handleCopyText"
                  @edit-message="handleEditMessage"
                  @like="handleLike"
                  @dislike="handleDislike"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <feedback-modal
        :is-open="feedbackModalIsOpen"
        :is-like="feedbackModalIsLike"
        @close="handleCloseModal"
        @send-feedback="handleSendFeedback"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  inject,
  ref,
  toRefs,
  onMounted,
  watch,
  PropType,
} from 'vue'
import markdownit from 'markdown-it'
import hljs from 'highlight.js'
import ChatHistoryItemFooter from './chat-history-item-footer.vue'
import BotAvatar from './bot-avatar.vue'
import LoaderAnimation from './loader-animation.vue'
import eventBus from '@/utils/event-bus'
import FeedbackModal from './feedback-modal.vue';
import { TAdditionalKwargs } from '@/utils/message-history'
import api from '@/services/api'
import ReferenceLink from './reference-link.vue'
type THandleSendFeedback = {
  isLike: boolean;
  userComment: string;
  userClassfication: string;
}

const props = defineProps({
  additionalKwargs: {
    type: Object as PropType<TAdditionalKwargs>,
    default: null,
    required: false,
  },
  chatId: {
    type: String,
    default: '',
    required: false,
  },
  message: {
    type: String,
      default: null,
    required: false,
  },
  role: {
    type: String,
    default: 'user',
    required: false,
  },
})
const viewType = inject('viewType') as string
const {
  additionalKwargs,
  chatId,
  message,
  role,
} = toRefs(props)
const feedbackModalIsOpen = ref(false);
const feedbackModalIsLike = ref(false);
const referenceLinksIsOpen = ref(false);
const isLoading = ref(false);
const likeWeight = ref(false);
const dislikeWeight = ref(false);
const isUser = message && role.value === 'user';
const isStreaming = role.value === 'streaming';
const isChat = viewType === 'chat'
const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
  highlight: (str: string, lang: string) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        hljs.highlightAll()
        const hljsR = hljs.highlight(str, { language: lang })
        return hljsR.value
      } catch (__) {
        console.log(__)
      }
    }
    return ''
  },
})
const result = ref('')
function getArticlesIds(articles: {article: number}[]) {
  return articles.map(({article}) => article)
}
function handleCopyText() {
  eventBus.emit('copy-text', message.value)
}
function handleEditMessage() {
  eventBus.emit('edit-message', message.value)
}
const handleBGUser = () => {
  return {
    'bg-user': isUser,
  }
}
const handleAlignUser = () => {
  return {
    'justify-end': isUser,
    'flex-col': !isUser,
  }
}
function handleLike() {
  feedbackModalIsOpen.value = true;
  feedbackModalIsLike.value = true;
  likeWeight.value = true;
  dislikeWeight.value = false;
}
function handleDislike() {
  feedbackModalIsOpen.value = true;
  feedbackModalIsLike.value = false;
  likeWeight.value = false;
  dislikeWeight.value = true;
}
function handleCloseModal() {
  feedbackModalIsOpen.value = false;
  likeWeight.value = false;
  dislikeWeight.value = false;
}
async function handleSendFeedback({isLike, userComment, userClassfication}: THandleSendFeedback) {
  const {
    articles,
    id: messageId,
    parent_id,
  } = additionalKwargs.value
  const articleIds = getArticlesIds(articles)
  const axiosApi = await api().axios()
  axiosApi.post(`/api/chat/${chatId.value}/message_feedback/${messageId}`, {
    feedback: Number(isLike),
    classification_feedback: userClassfication,
    commentary: userComment,
    articles_ids: articleIds,
    parent_id,
  }).then(() => {
    handleCloseModal()
    eventBus.emit('set-system-feedback', 'Feedback enviado com sucesso')
  }).catch((error) => {
    handleCloseModal()
    console.error(error)
    eventBus.emit('set-modal', {
      btn: [{
        text: 'Ok',
        type: 'default',
      }],
      isOpen: true,
      message: `Oops! Ocorreu um erro ao enviar seu feedback. Por favor, tente novmamente.`,
    })
  })
}
function handleOpenCLoseReferenceLinks() {
  referenceLinksIsOpen.value = !referenceLinksIsOpen.value
}
function handleAvatarClasses() {
  return {
    'mr-3': isChat,
  }
}
function handleMessage(message: string) {
  if(role.value === 'user')
    return message
  return md.render(sanitizeText(message) || '');
}
function sanitizeText(text: string) {
  const regex = [
    /!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)/g,
  ]
  const blockedText = regex.reduce((acc, cur) => {
    return acc.replace(cur, "[Conteúdo Bloqueado]")
  }, text)
  return blockedText
}
onMounted(() => {
  result.value =  handleMessage(message.value)
  isLoading.value = !message.value;
})
watch(message, (newValue) => {
  result.value = handleMessage(newValue);
  isLoading.value = !message.value;
})
</script>

<style lang="scss" scoped>
@import 'highlight.js/styles/atom-one-dark.css';
:deep(div) {
  .message {
    color: #333;
    span {
      white-space: normal;
      * + * {
        margin-top: 8px;
      }
      ul li:first-child {
        margin-top: 0;
      }
      ul li + li {
        margin-top: 4px;
      }
      table td {
        border: 1px solid #ccc;
        padding: 4px;
      }
    }
  }
  .message.bg-user {
    background-color: #f9f9f9;
    border-radius: 1rem;
  }
}
.history-item + .history-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
