<template>
  <aside-menu
    :aside-width="asideWidth"
    :is-open="asideMenuIsOpen"
    @toggle-open-close="toggleAsideMenu"
  />
  <main>
    <div class="absolute top-0 w-full">
      <div
        class="text-gray-700 bg-white h-screen max-h-[100dvh] overflow-auto flex flex-row"
      >
        <div
          id="chat-box-wrapper"
          class="h-screen max-h-[100dvh] w-full max-w-full flex flex-col"
          :style="chatBoxWrapperStyles"
        >
          <chat-header
            :title="navigatorname"
          />
          <chat-box />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import {
  defineProps,
  inject,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue'
import AsideMenu from '@/components/aside-menu.vue'
import ChatBox from '@/components/chat-box.vue'
import ChatHeader from '@/components/chat-header.vue'
import { toggleAsideMenu } from '@/store'
import eventBus from '@/utils/event-bus'

const navigatorname = ref('')
const props = defineProps({
  asideWidth: {
    type: String,
    default: 'chat',
    required: false,
  },
  isOpen: {
    type: Boolean,
    default: false,
    required: false,
  },
  chatBoxWrapperStyles: {
    type: Object,
    default: () => ({}),
    required: false,
  },
})
const {
  asideWidth,
  chatBoxWrapperStyles,
  isOpen: asideMenuIsOpen,
} = toRefs(props)
const viewType = inject('viewType')
const handleNavigatorName = (name: string) => {
  if(viewType === 'chat') return
  navigatorname.value = name
}
onMounted(() => {
  eventBus.on('change-navigator-name', handleNavigatorName)
})
onUnmounted(() => {
  eventBus.off('change-navigator-name', handleNavigatorName)
})
</script>

<style lang="scss" scoped>
main {
  display: contents;
}
</style>
