<template>
  <div
    class="flex m-auto w-full max-w-6xl px-4"
  >
    <div class="mr-3">
      <bot-avatar />
    </div> 
    <div class="mt-2 mb-4 text-3xl text-gray-800 font-semibold text-left flex items-center gap-4">
      <div v-if="articleFilter === 0">
        <div class="capitalize line-clamp-1 select-none">
          olá, {{ userName }}
        </div> 
        <div>
          <div class="font-medium text-gray-400 select-none">
            Como posso ajudar?
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="font-medium text-gray-400 select-none">
            Vamos falar sobre o artigo
          </div>
        </div>
        <div class="line-clamp-1 select-none">
          {{ articleName }}
        </div> 
      </div>
    </div> 
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  toRefs,
} from 'vue'
import BotAvatar from './bot-avatar.vue';

const props = defineProps({
  articleName: {
    type: String,
    required: true,
  },
  articleFilter: {
    type: Number,
    required: true,
  },
  userName: {
    type: String,
    required: true,
  },
})
const {
  userName,
} = toRefs(props)
</script>