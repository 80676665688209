<template>
  <div class="flex overflow-x-auto buttons text-gray-600 px-3 pb-2">
    <div class="flex">
      <button-component
        v-if="isUser"
        classes="visible p-1.5 hover:bg-black/5 rounded-full hover:text-black transition cursor-pointer"
        tooltip-text="Editar mensagem"
        @click="handleClick('edit-message')"
      >
        <ph-icon
          icon="PhNotePencil"
          :size="18"
          color="#707070"
        />
      </button-component>
      <button-component
        classes="visible p-1.5 hover:bg-black/5 rounded-full hover:text-black transition cursor-pointer"
        tooltip-text="Copiar"
        @click="handleClick('copy-text')"
      >
        <ph-icon
          icon="PhCopy"
          :size="18"
          color="#707070"
        />
      </button-component>
      <button-component
        v-if="!isUser"
        classes="visible p-1.5 hover:bg-black/5 rounded-full hover:text-black transition cursor-pointer"
        tooltip-text="Feedback Positivo"
        @click="handleLikeClick()"
      >
        <ph-icon
          icon="PhThumbsUp"
          :size="18"
          color="#707070"
          :weight="likeWeight"
        />
      </button-component>
      <button-component
        v-if="!isUser"
        classes="visible p-1.5 hover:bg-black/5 rounded-full hover:text-black transition cursor-pointer"
        tooltip-text="Feedback Negativo"
        @click="handleDislikeClick()"
      >
        <ph-icon
          icon="PhThumbsDown"
          :size="18"
          color="#707070"
          :weight="dislikeWeight"
        />
      </button-component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps,
  PropType,
  toRefs,
} from 'vue';
import ButtonComponent from './button-component.vue';
import PhIcon from './ph-icon.vue';

type TEvent = 'copy-text' | 'edit-message' | 'like' | 'dislike';
const props = defineProps({
  isUser: {
    type: Boolean,
    required: true,
  },
  likeWeight: {
    type: String as PropType<'regular' | 'fill'>,
    default: 'regular',
    required: false,
  },
  dislikeWeight: {
    type: String as PropType<'regular' | 'fill'>,
    default: 'regular',
    required: false,
  },
});
const {
  isUser,
  likeWeight,
  dislikeWeight,
} = toRefs(props);
const emit = defineEmits([
  'copy-text',
  'edit-message',
  'like',
  'dislike',
]);

function handleClick(event: TEvent) {
  emit(event);
}

function handleLikeClick() {
  emit('like');
}

function handleDislikeClick() {
  emit('dislike');
}
</script>