<template>
  <aside
    id="menu"
    class="
      h-screen max-h-[100dvh] min-h-screen select-none overflow-hidden
      text-gray-900 text-xs transition fixed z-50 top-0 left-0 bg-zinc-100
    "
    :class="classList"
  >
    <header-menu
      tooltip-text="Ocultar Menu"
      title="Histórico de Conversas"
      style="background-color: inherit"
    >
      <button-component
        tooltip-text="Nova conversa"
      >
        <ph-icon
          icon="PhPlus"
          @click="handleNewChat"
        />
      </button-component>
    </header-menu>
    <div
      class="py-2.5 my-auto flex flex-col justify-between h-screen z-50"
      :class="handleNavParentClass()"
    >
      <nav class="relative flex flex-col flex-1 overflow-y-auto">
        <div class="px-2 mt-0.5 mb-2 flex justify-center space-x-2">
          <input-search />
        </div>
        <div class="scroll-hover pl-2 my-2 flex-1 flex flex-col overflow-y-auto scrollbar-hidden svelte-141e0sl">
          <span
            v-if="!history.length"
            class="text-sm italic mx-4 text-zinc-500"
          >
            Nenhuma conversa por aqui... Clique no 
            <ph-icon 
              icon="PhPlus"
              color="rgb(113 113 122"
              class="inline-block"
              :size="16"
            />
            para começar uma agora.
          </span>
          <history-menu
            v-for="item in history"
            :key="item.dateTitle"
            :active-chat="store.activeChatId"
            :category-name="item.dateTitle"
            :history="item.items"
          />
        </div>
      </nav>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import {
  defineProps,
  inject,
  onMounted,
  onUnmounted,
  ref, 
  Ref,
  toRefs,
} from 'vue'
import HeaderMenu from './header-menu.vue'
import HistoryMenu from './history-menu.vue'
import InputSearch from './input-search.vue'
import PhIcon from './ph-icon.vue'
import useIframeControl from '@/hooks/iframe'
import eventBus from '@/utils/event-bus'
import { IHistoryChat } from '@/types'
import state, { toggleAsideMenu } from '@/store'
import ButtonComponent from './button-component.vue';
import {
  classifyDate,
  handleDeleteChat,
} from '@/utils/message-history'
import store from '@/store'

type TNewState = {
  newState: boolean
}

type TAsideMenuHistory = {
  dateTitle: string,
  items: IHistoryChat[]
}

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  asideWidth: {
    type: String,
    default: '',
    require: true,
  },
})
const {
  isOpen,
  asideWidth,
} = toRefs(props)
const iframe = useIframeControl()
const history: Ref<TAsideMenuHistory[]> = ref([])
const isAsideOpen = ref(isOpen.value);
const classList = ref('');
const navigatorAvatar = ref(state.navigatorAvatar)
const type = inject('viewType') as string
const device = inject('device') as string
const handleIsOpen = ({newState}: TNewState) => {
  const windowWidth = window.innerWidth
  const position = windowWidth < 760 ? 'absolute' : 'relative'
  classList.value = newState ? `w-${asideWidth.value} ${position}` : `-translate-x-full`
  isAsideOpen.value = newState
}

const handleNavParentClass = () => {
  return {
    'chat': type === 'chat',
    'mobile': device === 'mobile',
  }
}

const handleAsideMenuItems = (chatHistory: IHistoryChat[]) => {
  return chatHistory.reduce((acc: TAsideMenuHistory[], curr: IHistoryChat) => {
    const {last_message_date} = curr
    const dateTitle = classifyDate(last_message_date)
    const index = acc.findIndex((item) => item.dateTitle === dateTitle)
    if(index === -1){
      acc.push({
        dateTitle,
        items: [curr],
      })
    }else{
      acc[index].items.push(curr)
    }
    return acc
  }, [])
}

const handleMessageHistory = (hisotryMessages: IHistoryChat[]) => {
  history.value = handleAsideMenuItems(hisotryMessages)
}
const handleNavigatorAvatar = (avatar: string) => {
  navigatorAvatar.value = avatar
}
const handleNewChat = () => {
  if(type !== 'chat') toggleAsideMenu()
  eventBus.emit('new-chat')
  iframe.clearActiveChatId()
}
onMounted(() => {
  iframe.requestChatHistory()
  handleIsOpen({newState: isOpen.value})
  eventBus.on('toggle-aside-menu', handleIsOpen)
  eventBus.on('message-history-updated', handleMessageHistory)
  eventBus.on('change-navigator-avatar', handleNavigatorAvatar)
  eventBus.on('delete-chat', handleDeleteChat)
})
onUnmounted(() => {
  eventBus.off('toggle-aside-menu', handleIsOpen)
  eventBus.off('message-history-updated', handleMessageHistory)
  eventBus.off('change-navigator-avatar', handleNavigatorAvatar)
  eventBus.off('delete-chat', handleDeleteChat)
})
</script>

<style lang="scss" scoped>
aside{
  background-color: #f9f9f9;
  > div {
    height: calc(100dvh - 72px);
  }
  > div.chat, > div.mobile {
    height: calc(100dvh - 42px);
  }
}
aside#menu.w-chat{
  width: 360px;
}
.w-screen{
  width: 100%;
}
</style>
