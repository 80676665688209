<template>
  <button
    :class="handleClasses()"
    type="button"
    @mouseover="toggleTooltip"
    @mouseout="toggleTooltip"
  >
    <slot />
    <div
      v-if="isTooltipActive"
      class="btn-tooltip fixed bg-zinc-800 text-white px-2 py-1 rounded-lg top-2 text-sm font-normal"
      :style="tooltipStyle"
    >
      {{ tooltipText }}
    </div>
  </button>
</template>

<script lang="ts" setup>
import {
  CSSProperties,
  defineProps,
  nextTick,
  ref,
  toRefs,
} from 'vue'

type TTarget = {
  nodeName: string,
  parentElement: TTarget,
  getBoundingClientRect: () => DOMRect,
}

const props = defineProps({
  classes: {
    type: String,
    default: 'text-gray-600',
    required: false,
  },
  notRounded: {
    type: Boolean,
    default: false,
    required: false,
  },
  tooltipText: {
    type: String,
    default: '',
    required: false,
  },
})
const {
  classes,
  notRounded,
  tooltipText,
} = toRefs(props)
const isTooltipActive = ref(false)
const tooltipStyle = ref<CSSProperties>({
  top: '0',
  left: '0',
})
const handleClasses = () => {
  const userClasses = classes.value.split(' ')
  const defaultClasses = ['transition', 'self-center']
  const roundedClasses = notRounded ? [] : ['rounded-full', 'p-1.5']
  const classList = [...defaultClasses, ...roundedClasses, ...userClasses]
  return classList
}
function getParentButton(target: TTarget): TTarget {
  return target.nodeName === 'BUTTON' ? target : getParentButton(target.parentElement)
}
async function getXYPosition(target: TTarget): Promise<[number, number]> {
  const btn = getParentButton(target)
  const marginSpace = 8
  const btnInfo = btn.getBoundingClientRect()
  const divBox = document.querySelector('body') as HTMLElement
  const {
    height: windowHeight,
    width: windowWidth,
  } = divBox.getBoundingClientRect()
  await nextTick()
  const tooltipElement = document.querySelector('.btn-tooltip') as HTMLElement
  const tooltipInfo = tooltipElement.getBoundingClientRect() as DOMRect
  const calcTopPosition = btnInfo.top + btnInfo.height + marginSpace
  const calcLeftPosition = btnInfo.left - (tooltipInfo.width / 2) + (btnInfo.width / 2)
  const calcRightPosition = calcLeftPosition + tooltipInfo.width
  const calcBottomPosition = calcTopPosition + tooltipInfo.height
  const finalYPosition = calcBottomPosition > (windowHeight * .8) 
    ? btnInfo.top - tooltipInfo.height - marginSpace 
    : calcTopPosition
  const finalXPosition = calcRightPosition > (windowWidth - marginSpace)
  ? windowWidth - tooltipInfo.width - marginSpace
  : calcLeftPosition < 0
    ? marginSpace
    : calcLeftPosition
  return [finalYPosition, finalXPosition]
}
async function toggleTooltip(event: MouseEvent) {
  const target = event.target as unknown as TTarget
  const newStatus = !!tooltipText.value && !isTooltipActive.value
  isTooltipActive.value = newStatus
  if (!newStatus) return
  const [yPosition,xPosition] = await getXYPosition(target)
  tooltipStyle.value = {
    top: `${yPosition}px`,
    left: `${xPosition}px`,
    zIndex: 999,
  }
}
</script>

<style scoped lang="scss">
#icon-wrapper {
  margin-left: 0;
}
</style>