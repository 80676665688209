import axios, { AxiosInstance } from 'axios'
import store, { updateApiToken } from '@/store'
import useIframeControl from '@/hooks/iframe';
interface IApiReturn {
  axios: () => Promise<AxiosInstance>;
  fetch: (
    url: string,
    config: {
      method?: 'POST' | 'GET' | 'PUT' | 'DELETE',
      body?: string,
      headers?: HeadersInit,
    },
    thenCallback: (response: Response) => void,
    catchCallback: (error: Error) => void,
  ) => void;
}

async function getApiToken() {
  updateApiToken('')
  return new Promise<string>((resolve, reject) => {
    let limit = 1000
    useIframeControl().requestApiToken()
    const checkToken = setInterval(() => {
      if (store.userInfo.apiToken !== '') {
        clearInterval(checkToken)
        resolve(store.userInfo.apiToken)
      }
      if(limit === 0){
        reject('Token não encontrado')
      }
      limit--
    },10)
  })
}

const api = (baseURL?: string, apiToken?: string): IApiReturn => {
  return {
    axios: async () => {
      const storeApiToken = await getApiToken()
      return axios.create({
        baseURL: baseURL || store.apiURL,
        timeout: 20000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken || storeApiToken}`,
        },
      })
    },
    fetch: async (
      url: string,
      config: {
        method?: 'POST' | 'GET' | 'PUT' | 'DELETE',
        body?: string,
        headers?: HeadersInit,
      },
      thenCallback: (response: Response) => void,
      catchCallback: (error: Error) => void,
    ) =>{
      const apiToken = await getApiToken()
      fetch(`${store.apiURL}${url}`, {
        method: config.method || 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`,
          ...config.headers,
        },
        body: config.body,
      })
      .then(thenCallback)
      .catch(catchCallback);
    },
  }
}

export default api