<template>
  <div
    id="history"
    class=" scroll-hover pb-2.5 flex flex-col justify-between w-full flex-auto overflow-auto h-0 max-w-full"
  >
    <div class="h-full w-full flex flex-col pt-2 pb-4">
      <div class="h-full flex">
        <user-welcome v-if="messages.length === 0" />
        <div
          v-else
          class="w-full pt-2"
        >
          <div class="w-full">
            <div
              class="flex flex-col justify-between mb-3 max-w-6xl mx-auto rounded-lg group"
              :class="handleClasses()"
            >
              <div class="w-full">
                <chat-history-item
                  v-for="({content, role, additional_kwargs}, index) in messages"
                  :key="`msg-${index}`"
                  :chat-id="chatId"
                  :message="content"
                  :role="role"
                  :additional-kwargs="additional_kwargs"
                />
                <chat-history-item
                  v-if="newBotMessage !== null"
                  :message="newBotMessage"
                  role="streaming"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  inject,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue'
import ChatHistoryItem from './chat-history-item.vue'
import { ImessageChatBox } from '@/types'
import UserWelcome from './user-welcome.vue';

const props = defineProps({
  chatId: {
    type: String,
    required: true,
  },
  messages: {
    type: Array as PropType<ImessageChatBox[]>,
    required: true,
  },
  newMessage: {
    type: String as PropType<string | null>,
    default: null,
    required: false,
  },
})
const {
  chatId,
  messages,
  newMessage,
} = toRefs(props)
const newBotMessage = ref(null as string | null)
const viewType = inject('viewType') as string
const isChat = viewType === 'chat'
function handleClasses() {
  return {
    'px-5': isChat,
    'px-2': !isChat,
  }
}

watch(newMessage, (newValue) => {
  newBotMessage.value = newValue
})
</script>
