interface IframeControl {
  clearActiveChatId(): this;
  copyText(text: string): this;
  deleteChat(chatId: string): this;
  notifyClose(): this;
  openInNewTab(): this;
  requestApiToken(): this;
  requestAppAuth(): this;
  requestArticleName(): this;
  requestChatHistory(): this;
  saveActiveChatId(chatId: string): this;
  sendActiveChatMessages(messages: string): this;
  sendChatHistory(message: string): this;
  sendReadyStoreStatus(): this;
}

export default function useIframeControl(): IframeControl {
  function notify(args: object) {
    window.parent.postMessage({
      isWidget: true,
      ...args,
    }, '*')
  }
  return {
    clearActiveChatId() {
      notify({
        messageType: 'clearActiveChatId',
      })
      return this
    },
    copyText(text: string) {
      notify({
        messageType: 'copyText',
        message: text,
      })
      return this
    },
    deleteChat(chatId: string) {
      notify({
        messageType: 'deleteChat',
        message: chatId,
      })
      return this
    },
    notifyClose() {
      notify({
        isOpen: false,
        messageType: 'closeIframe',
      })
      return this
    },
    openInNewTab() {
      notify({
        messageType: 'openInNewTab',
      })
      return this
    },
    requestApiToken() {
      notify({
        messageType: 'requestApiToken',
      })
      return this
    },
    requestAppAuth() {
      notify({
        messageType: 'requestAppAuth',
        isAppAuth: true,
      })
      return this
    },
    requestArticleName() {
      notify({
        messageType: 'requestArticleName',
      })
      return this
    },
    requestChatHistory() {
      notify({
        messageType: 'requestChatHistory',
      })
      return this
    },
    saveActiveChatId(chatId: string) {
      notify({
        messageType: 'saveActiveChatId',
        message: chatId,
      })
      return this
    },
    sendActiveChatMessages(messages: string) {
      notify({
        messageType: 'saveActiveChatMessages',
        message: messages,
      })
      return this
    },
    sendChatHistory(message: string ) {
      notify({
        messageType: 'updateChatHistory',
        message,
      })
      return this
    },
    sendReadyStoreStatus() {
      notify({
        messageType: 'readyStoreStatus',
      })
      return this
    },
  }

}