import { TUserInfo } from "@/types"
import api from "./api"

export type TCreateChatResponse = {
  uuid: string,
  created_at: string,
}

export type TCreateChatReturn = {
  id: string,
  date: Date,
}

type TState = {
  apiToken: string;
  message: string;
  additional_kwargs?: TAdditionalkwargs;
}

const state: TState = {
  apiToken: '',
  message: '',
}

export type TChatList = {
  chat_id: string,
  title: string,
  last_message_date: string,
}

type TChatHistoryGet = {
  data: {
    chats: TChatList[]
  }
}

export type TAdditionalkwargs = {
  created_at: string;
  id: string;
  parent_id: string;
  articles: {
    article: number
    title: string;
  }[]
}

export function updateApiToken({apiToken}: TUserInfo) {
  state.apiToken = apiToken
}

export async function createChat(title: string): Promise<string> {
  try{
    const axiosApi = await api().axios()
    const response = await axiosApi.post(`/api/chat`, {
      title,
    })
    const {
      uuid: id,
    } = response.data.chat as TCreateChatResponse
    return id
  }catch(error){
    console.error('Erro ao criar chat:', error)
    return  ''
  }
}

const getFilters = (filter: number | null) => filter ? [{key: "help_id", value: filter}] : null

const handleBuffer = (buffer: string) => {
  let newMessage = ''
  let boundary = buffer.indexOf('\n');
  let additionalKwargs = null
  if(boundary === -1){
    return {
      additional_kwargs: null,
      newMessage: '',
    }
  }
  while (boundary !== -1) {
    const chunk = buffer.slice(0, boundary);
    buffer = buffer.slice(boundary + 1);
    try {
      const parsedJson = JSON.parse(chunk);
      const {
        additional_kwargs,
        message,
      } = parsedJson
      newMessage += message || '';
      additionalKwargs = additional_kwargs;
    } catch (e) {
      console.error('Erro ao processar o JSON:', e);
    }
    boundary = buffer.indexOf('\n');
  }
  return {
    additional_kwargs: additionalKwargs,
    newMessage,
  }
}

export async function sendMessage(
  messageId: string,
  userPrompt: string,
  callback: (message: string) => void,
  resolve: (additional_kwargs?: TAdditionalkwargs) => void,
  errorCallback: () => void,
  filter: number | null,
) {
  const filters = getFilters(filter)
  api().fetch(`/api/chat/${messageId}`, {
    method: 'POST',
    body: JSON.stringify({
      messages: [{
        content: userPrompt,
        role: "user",
      }],
      filters,
    }),
  },
  response => {
    const decoder = new TextDecoder('utf-8');
    const reader = response?.body?.getReader();
    return new ReadableStream({
      start(controller) {
        return pump();
        function pump(): Promise<ReadableStreamDefaultReader<Uint8Array> | undefined> | undefined {
          return reader?.read().then(({ value, done }) => {
            if(!value && !state.message){
              errorCallback()
              return;
            }
            const buffer = decoder.decode(value, {stream: true})
            const {
              additional_kwargs,
              newMessage,
            } = handleBuffer(buffer)
            state.message += newMessage
            if(buffer)
              state.additional_kwargs = additional_kwargs
            callback(state.message)
            if (done) {
              controller.close();
              resolve(state.additional_kwargs)
              state.message = ''
              return;
            }
            controller.enqueue(value);
            return pump();
          });
        }
      },
    })
  },
  error => {
    console.error('Erro ao enviar mensagem para o bot:', error)
    resolve()
  });
}

export async function listChat(): Promise<TChatList[]> {
  const axiosApi = await api().axios()
  const chatHistory: TChatHistoryGet = await axiosApi.get(`/api/chat`)
  return chatHistory.data.chats
}
