import { reactive } from 'vue';

type Callback<T = unknown> = (payload: T) => void;

interface Events {
  [key: string]: Callback[];
}

const eventBus = reactive({
  events: {} as Events,

  on<T>(event: string, callback: Callback<T>): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback as Callback);
  },
  
  off<T>(event: string, callback: Callback<T>): void {
    if (!this.events[event]) return;
    this.events[event] = this.events[event].filter(cb => cb !== callback);
  },
  
  emit<T>(event: string, payload?: T): void {
    if (!this.events[event]) return;
    this.events[event].forEach(callback => callback(payload));
  },
});

export default eventBus;