<template>
  <div
    v-if="isOpen"
    class="w-full empty:hidden"
  >
    <div
      class="min-h-[96px] w-full"
      style="opacity: 1;"
    >
      <div class="relative my-2 flex w-full flex-col gap-3 rounded-lg border border-token-border-light p-4">
        <button-component
          class="absolute right-4 top-4 text-default"
          tooltip-text="Fechar"
          @click="emit('close')"
        >
          <ph-icon
            icon="PhX"
            :size="20"
            color="#707070"
          />
        </button-component>
        <div class="text-default text-token-text-secondary">
          {{ title }}
        </div>
        <div class="flex-grow overflow-y-auto">
          <div
            v-if="!isLike"
            class="flex flex-col gap-6"
          >
            <div>
              <div class="flex flex-wrap gap-3">
                <button
                  v-for="(option, index) in negativeFeedbackOptions"
                  :key="option.label"
                  class="
                    rounded border border-token-border-light px-3 py-1 text-default text-token-text-secondary 
                    hover:text-token-text-primary hover:bg-gray-100
                  "
                  :class="{ 'bg-zinc-500 hover:bg-zinc-400 text-white': option.isSelected }"
                  @click="handleFeedbackOptionClick(index)"
                >
                  {{ option.label }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="!isLike"
            class="text-default text-token-text-secondary mt-3"
          >
            Conte-nos o que você não gostou (Opcional)
          </div>
          <div
            class="
              rounded border shadow-none border-token-border-medium focus-within:border-token-border-xheavy 
              focus-within:ring-1 focus-within:ring-token-text-secondary px-3 py-2 mt-2 mx-px
            "
          >
            <div class="relative">
              <textarea
                id="feedback"
                v-model="userComment"
                class="
                  block w-full border-0 p-0 placeholder-gray-500 shadow-none outline-none focus-within:shadow-none 
                  focus-within:outline-none focus-within:ring-0 focus:border-none focus:ring-0 sm:text-default 
                  bg-token-main-surface-primary text-token-text-primary resize-none
                "
              />
            </div>
          </div>
          <div class="flex flex-col gap-3 sm:flex-row-reverse mt-5 sm:mt-4 items-end">
            <button
              id="send-feedback"
              class="hover:bg-inherit btn relative btn-primary py-1 px-4 rounded-full"
              :class="sendBtnClasses()"
              @click="handleSendFeedback()"
            >
              <span
                class="text-default"
              >
                Enviar Feedback
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue'
import ButtonComponent from './button-component.vue';
import PhIcon from './ph-icon.vue';
import store from '@/store';

type TNegativeFeedbackOption = {
  label: string,
  isSelected: boolean,
}

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  isLike: {
    type: Boolean,
    required: true,
  },
})

const {
  isOpen,
  isLike,
} = toRefs(props)

const emit = defineEmits([
  'close',
  'send-feedback',
])
const title = ref('Como você classifica o feedback?');
const isSendBtnDisabled = ref(!isLike.value)
const likeFeedbackContent = ref('')
const dislikeFeedbackContent = ref('')
const userComment = ref('')
const negativeFeedbackOptions = ref([] as TNegativeFeedbackOption[])
watch(isLike, (newIsLikeValue) => {
  title.value = newIsLikeValue
    ? 'Conte-nos o que você mais gostou (Opcional)'
    : 'Como você classifica o feedback?'
  isSendBtnDisabled.value = !newIsLikeValue
  updateLikeDislikeContent(newIsLikeValue)
})
watch(negativeFeedbackOptions, (value) => {
  isSendBtnDisabled.value = value.every(({isSelected}) => !isSelected)
})
const handleSendFeedback = () => {
  const userClassfication = !isLike.value 
    ? getSelectedNegativeFeedback(negativeFeedbackOptions.value)
    : null
  emit('send-feedback', {
    isLike: isLike.value,
    userComment: userComment.value,
    userClassfication,
  })
}
const sendBtnClasses = () => {
  return {
    'cursor-not-allowed': isSendBtnDisabled.value,
  }
}
const handleFeedbackOptionClick = (index: number) => {
  negativeFeedbackOptions.value = negativeFeedbackOptions.value.map((option, i) => {
    const isSelected = i === index ? !option.isSelected : false
    return {...option, isSelected}
  })
}
const updateLikeDislikeContent = (newIsLikeValue: boolean) => {
  likeFeedbackContent.value = !newIsLikeValue ? userComment.value : likeFeedbackContent.value
  dislikeFeedbackContent.value = newIsLikeValue ? userComment.value : dislikeFeedbackContent.value
  userComment.value = newIsLikeValue ? likeFeedbackContent.value : dislikeFeedbackContent.value
}
const getSelectedNegativeFeedback = (negativeFeedbackOptions: {label: string, isSelected: boolean}[]) => {
  const feedback = negativeFeedbackOptions.filter(({isSelected}) => isSelected)[0]
  return feedback.label
}
onMounted(() => {
  const feedbackName = 'negativeFeedback'
  const profileEntries = Object.entries(store.powerappProfile)
  negativeFeedbackOptions.value = profileEntries
    .filter(([key]) => key.includes(feedbackName))
    // eslint-disable-next-line
    .map(([_, value]) => ({
      label: `${value}`,
      isSelected: false,
    }))
})
</script>

<style scoped lang="scss">
#send-feedback {
  width: fit-content;
  background-color: #419641;
  &.cursor-not-allowed {
    background-color: #c4c4c4;
  }
  span {
    color: #fff;
  }
}
#send-feedback:hover {
  background-color: #5CB85C;
  &.cursor-not-allowed {
    background-color: #c0c0c0;
  }
}
</style>