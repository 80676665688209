<template>
  <div class="container">
    <div class="loader text-gray-700 text-default pl-3" />
  </div>
</template>

<style lang="scss" scoped>
.loader {
  width: fit-content;
  clip-path: inset(0 10px 0 0);
  animation: l4 2s steps(4) infinite;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.loader:before {
  content:"Estou procurando respostas...";
  font-size: 13px;
}
@keyframes l4 {to{clip-path: inset(0 -4px 0 0)}}
</style>